import React from 'react';
import Badge from 'react-bootstrap/Badge';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import * as Icon from 'react-bootstrap-icons';

import { GetSampleExamExtendedDto } from '../../../common/snrwbCore/autogenerated/snrwbApiClient';
import ProductTypeNotApproved from '../ProductTypes/ProductTypeNotApproved';

export const SampleExamType: React.FC<{
  exam: GetSampleExamExtendedDto;
}> = props => {
  if (!props.exam) {
    return <></>;
  }

  return (
    <>
      {!props.exam.productType.approved && (
        <ProductTypeNotApproved className="me-1" />
      )}
      {props.exam.approved && !props.exam.completionDate && (
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip>Badanie podpisane</Tooltip>}
        >
          <Icon.Pencil className="text-success me-1" />
        </OverlayTrigger>
      )}
      {props.exam.notApprovedResults > 0 && (
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip>Liczba wyników próbek do zatwierdzenia</Tooltip>}
        >
          <Badge bg="info" className="me-1">
            {props.exam.notApprovedResults}
          </Badge>
        </OverlayTrigger>
      )}
      {props.exam.completionDate && (
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip>Obsługa badania została zakończona</Tooltip>}
        >
          <Badge bg="success" className="me-1">
            ✓
          </Badge>
        </OverlayTrigger>
      )}
      {props.exam.needVerification && (
        <OverlayTrigger
          placement="bottom"
          overlay={
            <Tooltip>
              Istnieje niezweryfikowana próbka przekazana do publikacji
            </Tooltip>
          }
        >
          <Badge bg="danger" className="me-1">
            !
          </Badge>
        </OverlayTrigger>
      )}
      {props.exam.unpublishable && (
        <OverlayTrigger
          placement="bottom"
          overlay={
            <Tooltip>Badanie oznaczono jako niemożliwe do publikacji</Tooltip>
          }
        >
          <Badge bg="warning" className="me-1">
            !
          </Badge>
        </OverlayTrigger>
      )}
      {props.exam.publications && (
        <OverlayTrigger
          placement="bottom"
          overlay={
            <Tooltip>
              Wyniki zostały opublikowane pod nr: {props.exam.publications}
            </Tooltip>
          }
        >
          <Icon.Globe className="me-1" />
        </OverlayTrigger>
      )}
      {props.exam.positiveResults > 0 && (
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip>Liczba pozytywnych wyników</Tooltip>}
        >
          <Badge bg="success" className="me-1">
            {props.exam.positiveResults}
          </Badge>
        </OverlayTrigger>
      )}
      {props.exam.negativeResults > 0 && (
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip>Liczba negatywnych wyników</Tooltip>}
        >
          <Badge bg="danger" className="me-1">
            {props.exam.negativeResults}
          </Badge>
        </OverlayTrigger>
      )}
    </>
  );
};
